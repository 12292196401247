<template>
  <form @submit.prevent>
    <CourseType />
    <div class="row">
      <CustomSelect
        id="course-product-id"
        v-model:model-value="productId"
        label="В какой курс добавить этот тариф"
        :options="productOptions"
        tip="Выберите из существующих курсов"
        :errors="errors.product_ids"
        :search="
          (query) => store.dispatch('products/fetchProductsForSelect', query)
        "
        required
        class="mb-3 col-6"
      />
    </div>
    <div class="row">
      <div class="mb-3 col-3">
        <label
          for="course-stream-name"
          class="mb-2"
        >
          Поток
        </label>
        <input
          id="course-stream-name"
          v-model.trim="course.stream_name"
          type="text"
          class="form-control"
          :class="errors.stream_name && 'is-invalid'"
          maxlength="10"
        />
        <span
          v-for="(error, index) in errors.stream_name"
          :key="index"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
        <small class="form-text text-muted">
          Например, Поток 3. Макс. 10 символов
        </small>
      </div>
      <div class="mb-3 col-3">
        <label
          for="course-tariff-name"
          class="mb-2"
        >
          Тариф
        </label>
        <input
          id="course-tariff-name"
          v-model.trim="course.tariff_name"
          type="text"
          class="form-control"
          :class="errors.tariff_name && 'is-invalid'"
          maxlength="40"
        />
        <span
          v-for="(error, index) in errors.tariff_name"
          :key="index"
          class="invalid-feedback"
        >
          {{ error }}
        </span>
        <small class="form-text text-muted">
          Например, Комфорт. Макс. 40 символов
        </small>
      </div>
    </div>
    <div class="row">
      <CourseUrl
        course-item-type="course"
        class="col-6 mb-3"
      />
    </div>
    <div class="row">
      <CustomSelect
        id="course-po-user-id"
        v-model:model-value="course.po_user_id"
        :options="poOptions"
        :errors="errors.po_user_id"
        label="Директор"
        tip="Список пользователей с ролью slurm_manager"
        class="mb-3 col-6"
        required
        :search="(query) => store.dispatch('course/fetchProductOwners', query)"
      />
    </div>
    <div class="row">
      <CustomSelect
        v-if="courses"
        id="linked-courses-ids"
        v-model:model-value="course.linked_courses_ids"
        :options="coursesOptions"
        label="Связанные тарифы"
        tip="Будут доступны студенту после покупки"
        class="mb-3 col-6"
        multiple
      />
    </div>
    <CourseDuration />
    <transition
      mode="out-in"
      name="fade"
    >
      <div
        v-if="course.course_type === 'slurm'"
        class="row"
      >
        <div class="mb-3 col-6">
          <label
            for="course-zoom-url"
            class="mb-2"
          >
            Ссылка на zoom
          </label>
          <input
            id="course-zoom-url"
            v-model.trim="course.zoom_url"
            :class="errors.zoom_url && 'is-invalid'"
            type="text"
            class="form-control"
          />
          <span
            v-for="(error, index) in errors.zoom_url"
            :key="index"
            class="invalid-feedback"
          >
            {{ error }}
          </span>
        </div>
      </div>
    </transition>
    <div class="row">
      <CustomSelect
        id="course-speaker-ids"
        v-model:model-value="course.speaker_ids"
        :options="speakersOptions"
        class="col-12 mb-3"
        multiple
        label="Спикеры"
      />
    </div>
    <div class="mb-3">
      <label
        for="memo"
        class="mb-2"
      >
        Памятка
      </label>
      <Editor
        id="memo"
        v-model:model-value="course.memo"
      />
    </div>
    <!-- Fast fix for change extended_description
    in tariffs types different from subscriptions and
    bundles. Need write tests and validations -->
    <div class="row">
      <div class="col-12 mb-3">
        <label
          for="extended-description"
          class="form-label"
        >
          Расширенное описание (для продающей страницы)
        </label>
        <Editor
          id="extended-description"
          v-model:model-value="course.extended_description"
        />
      </div>
    </div>

    <div
      v-if="course.course_type === 'profession'"
      class="row mb-custom"
    >
      <form
        @submit.prevent="addInnerCourse"
        class="col-6 mb-4"
      >
        <label for="new-inner-course-input">Тарифы внутри профессии</label>
        <div class="input-group flex-nowrap">
          <CustomSelect
            v-model:model-value="innerCourseId"
            :options="coursesExcludeInner"
            id="new-inner-course-input"
            class="w-100"
            search
          />
          <button
            class="btn btn-primary rounded-right"
            type="submit"
          >
            Добавить
          </button>
        </div>
      </form>

      <div class="row">
        <div class="col-6">
          <template v-if="currentInnerCourses.length">
            <draggable
              v-model:model-value="currentInnerCourses"
              class="d-flex flex-column gap-3"
            >
              <template #item="{ element }">
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    icon="grip-vertical"
                    class="text-muted drag-icon me-3"
                  />
                  <div class="card p-2 w-100">
                    <span>{{ element.name }}</span>
                  </div>
                  <button
                    @click="removeInnerCourse(element.id)"
                    type="button"
                    class="btn btn-link text-danger"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </template>
            </draggable>
          </template>
          <div
            v-else
            class="card p-2"
          >
            Еще не добавили тарифы
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import CustomSelect from '@/components/CustomSelect';
import CourseDuration from '@/components/courses/CourseDuration';
import CourseType from '@/components/courses/CourseType';
import CourseUrl from '@/components/courses/CourseUrl';
import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

const store = useStore();
const route = useRoute();

store.dispatch('courses/fetchCoursesOmitPagination');

const course = computed(() => store.getters['course/getCourse']);
const errors = computed(() => store.getters['course/getErrors']);

const products = computed(() => store.getters['products/getProducts']);
const productOptions = computed(() =>
  useCustomSelectOptions(products.value, 'id', 'name'),
);
const productId = computed({
  get: () => course.value.product_ids?.[0] ?? null,
  set: (value) => {
    course.value.product_ids = value ? [value] : null;
  },
});

const poOptions = computed(() => {
  const productOwners = store.getters['course/getProductOwners'];
  return productOwners
    ? productOwners.map((po) => ({
        value: po.id,
        name: po.full_name || po.email,
      }))
    : [];
});

const speakers = computed(() => store.getters['speakers/getSpeakers']);
const speakersOptions = computed(() =>
  useCustomSelectOptions(speakers.value, 'id', 'full_name'),
);

const courses = computed(
  () => store.getters['courses/getCoursesOmitPagination'],
);
const coursesOptions = computed(() => {
  return useCustomSelectOptions(courses.value, 'id', 'name');
});

const innerCourses = computed(() => course.value?.inner_courses || []);
const currentInnerCourses = ref(innerCourses.value);
const innerCourseId = ref(null);

const coursesExcludeInner = computed(() => {
  if (!courses.value) return [];

  const innerIds = new Set(currentInnerCourses.value.map((c) => c.id));
  return useCustomSelectOptions(
    courses.value.filter((course) => !innerIds.has(course.id)),
    'id',
    'name',
  );
});

const addInnerCourse = () => {
  if (
    !innerCourseId.value ||
    currentInnerCourses.value.some((c) => c.id === innerCourseId.value)
  ) {
    return;
  }

  const course = courses.value.find((c) => c.id === innerCourseId.value);
  innerCourseId.value = null;

  if (course) {
    currentInnerCourses.value.push(course);
  }
};

const removeInnerCourse = (id) => {
  currentInnerCourses.value = currentInnerCourses.value.filter(
    (c) => c.id !== id,
  );
};

watch(
  currentInnerCourses,
  (newCourses) => {
    course.value.inner_course_ids = newCourses.map((c) => c.id);
  },
  { immediate: true, deep: true },
);

if (route.query.product && !isNaN(route.query.product)) {
  course.value.product_ids = [Number(route.query.product)];
}
</script>

<style>
.mb-custom {
  margin-bottom: 18rem;
}

#new-inner-course-input > #vs4__combobox {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
