<template>
  <div class="row">
    <CustomSelect
      id="course-displayed-type"
      v-model:model-value="course.displayed_type"
      :options="options"
      :errors="errors.displayed_type"
      label="Тип"
      class="col-6 mb-3"
      required
    />
  </div>
</template>

<script setup>
import CustomSelect from '@/components/CustomSelect';

import {
  getCourseTypeByDisplayedType,
  getDisplayedTypeOptionsExceptContainers,
  getDisplayedTypeOptionsOnlyContainers,
  formatDisplayedType,
} from '@/custom_functions/course_types';

import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();

const isContainerPage = computed(() =>
  ['containers_new', 'containers_edit'].includes(route.name),
);

const course = computed(() => {
  return store.getters[
    isContainerPage.value ? 'container/getContainer' : 'course/getCourse'
  ];
});

const errors = computed(() => {
  return store.getters[
    isContainerPage.value ? 'container/getErrors' : 'course/getErrors'
  ];
});

const route = useRoute();

const options = computed(() => {
  if (isContainerPage.value) return getDisplayedTypeOptionsOnlyContainers();
  return getDisplayedTypeOptionsExceptContainers();
});

watch(
  () => course.value.displayed_type,
  (value) => {
    course.value.stream_name = formatDisplayedType(value);
    if (value) course.value.course_type = getCourseTypeByDisplayedType(value);
    if (course.value.course_type === 'demo') {
      course.value.price = '0';
      course.value.expire_days = null;
    } else course.value.expire_days = 730;
    if (value !== 'online_course' && course.value.course_type !== 'slurm') {
      course.value.date_from = course.value.date_to = null;
      course.value.show_in_calendar = false;
    }
  },
);
</script>
