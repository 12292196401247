<template>
  <textarea :id="editorId" />
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, computed } from 'vue';
import { apiClient } from '@/custom_functions/api_client';
import { contentUiCss, contentCss } from '@/custom_functions/tinymce.js';

// eslint-disable-next-line no-unused-vars
import Prism from 'prismjs';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-haml';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-csharp';

import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/models/dom';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/image';
import 'tinymce/plugins/accordion';

import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/default.css';

import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import cpp from 'highlight.js/lib/languages/cpp';
import java from 'highlight.js/lib/languages/java';
import go from 'highlight.js/lib/languages/go';
import yaml from 'highlight.js/lib/languages/yaml';
import xml from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import php from 'highlight.js/lib/languages/php';
import ruby from 'highlight.js/lib/languages/ruby';
import csharp from 'highlight.js/lib/languages/csharp';
import haml from 'highlight.js/lib/languages/haml';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('java', java);
hljs.registerLanguage('go', go);
hljs.registerLanguage('yaml', yaml);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('css', css);
hljs.registerLanguage('php', php);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('haml', haml);

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Введите текст здесь...',
  },
  height: {
    type: Number,
    default: 400,
  },
});

const emit = defineEmits(['update:modelValue']);

const contentStyle = ref(contentUiCss + '\n' + contentCss);

const detectLanguage = (code) => {
  const { language } = hljs.highlightAuto(code);

  const languageMap = {
    javascript: 'javascript',
    python: 'python',
    cpp: 'cpp',
    java: 'java',
    go: 'go',
    yaml: 'yaml',
    xml: 'markup',
    css: 'css',
    php: 'php',
    ruby: 'ruby',
    csharp: 'csharp',
    haml: 'haml',
  };

  return languageMap[language] || 'markup';
};

const editorId = computed(() => `${props.id}-${Date.now()}`);

const codesampleLanguages = [
  { text: 'Автоматическое определение', value: 'auto' },
  { text: 'HTML/XML', value: 'markup' },
  { text: 'JavaScript', value: 'javascript' },
  { text: 'CSS', value: 'css' },
  { text: 'PHP', value: 'php' },
  { text: 'Ruby', value: 'ruby' },
  { text: 'Python', value: 'python' },
  { text: 'Java', value: 'java' },
  { text: 'C', value: 'c' },
  { text: 'C#', value: 'csharp' },
  { text: 'C++', value: 'cpp' },
  { text: 'Go', value: 'go' },
  { text: 'HAML', value: 'haml' },
  { text: 'YAML', value: 'yaml' },
];

const plugins =
  'advlist emoticons link lists table searchreplace visualblocks code fullscreen codesample insertdatetime media table emoticons wordcount image accordion';
const toolbar =
  'undo redo | styles | bold italic underline strikethrough | \
  forecolor backcolor | code codesample | alignleft aligncenter alignright | \
  blockquote bullist numlist outdent indent | image link media | \
  removeformat | fullscreen | custom | accordion';

const getVideoTemplate = (data) => {
  const hasToken = data.source.includes('?token=');
  return `<div><video class="video-player" width="300px" height="140px"><source src="${
    data.source
  }${!hasToken ? '?token=' : ''}" type="video/mp4" /></video></div>`;
};

const uploadFile = async (blobInfo, progress) => {
  const formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  const response = await apiClient.post('v2/images', formData, {
    onUploadProgress(e) {
      progress((e.loaded / e.total) * 100);
    },
    skipErrorHandling: true,
  });

  return new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status < 300) {
      const json = response.data;
      if (!json || typeof json.image.url != 'string') {
        return reject('Invalid JSON: ' + response);
      }
      return resolve(json.image.url);
    } else {
      if (response.status) return reject(`HTTP Error ${response.status}`);
      else return reject('HTTP Error');
    }
  });
};

const setupSettings = (editor) => {
  editor.on('init', () => {
    editor.setContent(props.modelValue || '');
  });
  editor.on('input', () => {
    emit('update:modelValue', editor.getContent());
  });
  editor.on('focusout', () => {
    emit('update:modelValue', editor.getContent());
  });

  editor.on('BeforeSetContent', (e) => {
    if (e.content.includes('class="language-auto"')) {
      const trimmedContent = document.createElement('div');
      trimmedContent.innerHTML = e.content;
      const preElement = trimmedContent.firstChild;
      const textContent = preElement.textContent;

      const lang = detectLanguage(textContent);

      e.content = e.content.replace(
        'class="language-auto"',
        `class="language-${lang}"`,
      );
    }
  });
};

onMounted(() => {
  tinymce.init({
    selector: `#${editorId.value}`,
    browser_spellcheck: true,
    skin: false,
    promotion: false,
    content_css: false,
    branding: false,
    contextmenu: false,
    automatic_uploads: true,
    placeholder: props.placeholder,
    content_style: contentStyle.value,
    plugins,
    codesample_global_prismjs: true,
    codesample_languages: codesampleLanguages,
    toolbar,
    height: props.height,
    video_template_callback: getVideoTemplate,
    setup: setupSettings,
    images_upload_handler: uploadFile,
    details_initial_state: 'expanded',
    details_serialized_state: 'collapsed',
  });
});
</script>
